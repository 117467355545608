import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/Chooser.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Mentions légales`}</h2>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Dernière actualisation : 4 novembre 2021`}</em></p>
    </blockquote>
    <p>{`La Plateforme digitale (ci-après désigné la « Plateforme ») est éditée par ENIA, société par actions
simplifiée au capital de 1090 €, immatriculée au RCS de Nanterre sous le numéro 878 058 346 dont
le siège social est sis au 21 rue de la Station, 92600 Asnières sur Seine (ci-après « ENIA »).`}</p>
    <p>{`Directeur de publication : Monsieur Jacques-Arthur de Saint Germain`}</p>
    <p>{`Hébergeur : OVH`}</p>
    <h3>{`Les données personnelles – charte de confidentialité`}</h3>
    <p>{`Conformément au Règlement 2016/679 du 27 avril 2016 relatif à la protection des personnes
physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces
données et la loi française en conformité avec le RGPD, la Charte informe de la manière dont ENIA
utilise et protège les informations transmises par le Client / Utilisateur et collectées automatiquement
lorsqu’il utilise la Plateforme ENIA.`}</p>
    <p>{`Cette Charte est susceptible d’être modifiée ou complétée à tout moment, notamment en vue de se
conformer à toute évolution législative, réglementaire ou technologique.`}</p>
    <p>{`La collecte ou la réception de données à caractère personnel est toujours justifiée par les services
rendus / offerts par ENIA au Client / Utilisateur. Le Client / Utilisateur est informé que ENIA collecte ou
reçoit des données à caractère personnel nécessaires à l’exécution de sa prestation de mise en
relation et prestation de services connexes.`}</p>
    <p>{`Les données qui peuvent être collectées sont les suivantes : adresse email, mot de passe, nom,
prénom, téléphone, adresse physique, coordonnées GPS, dénomination sociale, numéro de Siret et
TVA, ainsi que toute information relative aux caractéristiques du Produit proposé sur la Plateforme.`}</p>
    <p>{`Ces données restent confidentielles et ne sont utilisées que par ENIA dans les conditions définies, ou
par des tiers liés à ENIA par contrat pour l’exécution de tâches sous-traitées nécessaires à la bonne
réalisation des services.`}</p>
    <p>{`Les données collectées font l'objet d'un traitement informatique par ENIA, aux fins d'enregistrement et
de traitement des demandes de mise en relation, de commandes, des paiements, de gestion et suivi
de la relation avec les Client / Utilisateur, d’envoi de newsletter et d’offres, d'études commerciale et
marketing, de facturation, de réponse à d'éventuelles demandes et de statistiques pour améliorer
l’offre. Ces informations collectées font l’objet d’une déclaration auprès de la CNIL.`}</p>
    <p>{`ENIA peut être amenée à partager des informations concernant le Client / Utilisateur avec certains
sous-traitants lorsque cela est nécessaire pour le paiement ou la bonne réalisation du service. ENIA
s’interdit de traiter, héberger ou transférer les informations collectées vers un pays situé en dehors de
l’UE ou reconnu inadéquat par la Commission européenne sans en avoir préalablement informé le
Client / Utilisateur. Dans le cadre de la bonne réalisation du service, ENIA doit transférer des données
hors de l’UE mais ENIA s’engage à s’assurer que ce transfert est effectué dans des pays reconnus
comme assurant un niveau de protection adéquat des données et présentant des garanties
suffisantes au regard des exigences du RGPD. Plus précisément, les données peuvent être
transférées au sein du Partenaire de paiement Stripe.`}</p>
    <p>{`ENIA est susceptible de collecter et traiter certaines données concernant le Client / Utilisateur en
raison d’obligations légales ou pour répondre à son intérêt légitime.`}</p>
    <p>{`Les données du Client / Utilisateur seront conservées selon la durée strictement nécessaire pour
réaliser les finalités précitées (durée de la relation contractuelle, durée nécessaire au traitement de la
demande, etc.) sauf opposition du Client / Utilisateur, et, au maximum pour une durée de trois (3) ans.`}</p>
    <p>{`À l’issue des durées mentionnées, les données personnelles seront supprimées ou ENIA procédera à
leur anonymisation.`}</p>
    <p>{`ENIA s’engage à ne pas revendre ou divulguer ces données en dehors de ses partenaires
(ressources externes) et à prendre toutes les précautions nécessaires afin de préserver la sécurité
des données. Si un incident impactant l’intégrité ou la confidentialité des données du Client /
Utilisateur est porté à la connaissance d’ENIA, elle informera le Client / Utilisateur et lui
communiquera les mesures de corrections prises.`}</p>
    <p>{`Pour que la protection des données à caractère personnel soit optimale sur Internet, ENIA a sécurisé
au maximum sa Plateforme.`}</p>
    <p>{`Tout Client / Utilisateur a la possibilité de s'opposer sans frais, hormis ceux liés à la transmission du
refus le cas échéant, à l'utilisation de ses données personnelles par ENIA. Le Client / Utilisateur peut
demander à tout moment le retrait du consentement et à accéder aux données à caractère personnel
le concernant, à les rectifier, s’opposer à certains traitements, à limiter leurs traitements, à la
portabilité de ses données, à les effacer ou encore à l’oubli, en contactant le Délégué à la Protection
des Données (DPO), à savoir Monsieur Jacques-Arthur de Saint Germain par courrier à l’adresse
postale : ENIA - 21 rue de la Station, 92600 Asnières sur Seine.`}</p>
    <p>{`Cette demande doit identifier précisément la personne et être accompagnée d’une copie d’une pièce
d’identité.`}</p>
    <p>{`ENIA s’engage à répondre à la demande d’accès, de rectification ou d’opposition ou toute autre
demande complémentaire dans un délai raisonnable qui ne saurait dépasser 30 jours à compter de la
réception de la demande.`}</p>
    <h3>{`Les Cookies`}</h3>
    <p>{`Pour permettre au Client / Utilisateur de la Plateforme de bénéficier des services proposés, la
Plateforme utilise des Cookies (fichiers stockés automatiquement par les navigateurs web de
l’appareil connecté) et autres moyens d’enregistrement de données de navigation collectés.`}</p>
    <p>{`Les données ainsi obtenues visent à personnaliser, faciliter et optimiser la navigation sur la
Plateforme, et ont également vocation à permettre diverses mesures de fréquentation.`}</p>
    <p>{`La Plateforme est susceptible de contenir des Cookies émis par des tiers (société de mesure
d'audience, prestataire de publicité ciblée, etc.) permettant à ces derniers, pendant la durée de
validité de leurs Cookies de recueillir des informations de navigation et de déterminer les contenus
publicitaires susceptibles de correspondre aux centres d’intérêts du Client / Utilisateur. Les cookies
commerciaux ne contiennent pas de données à caractère personnel.`}</p>
    <p>{`Le Client / Utilisateur peut empêcher la collecte d’information le concernant en cliquant sur les liens
correspondant et les propres politiques de confidentialité des cookies émis par des tiers. Ainsi, le
Client / Utilisateur peut gérer ses Cookies à partir de son logiciel de navigation ou lorsque cela lui est
proposé à partir d’un lien présent sur la Plateforme.`}</p>
    <p>{`Tant que le Client / Utilisateur ne décide pas de désactiver les Cookies, il accepte que la Plateforme
puisse les utiliser.`}</p>
    <p>{`L’enregistrement d’un cookie reste subordonné à la volonté de l’utilisateur et l’utilisateur a la
possibilité de configurer son logiciel de navigation de sorte à s’opposer, totalement ou non,
indéfiniment ou ponctuellement, à l’enregistrement de cookies. Pour ce faire, l’utilisateur doit :`}</p>
    <ul>
      <li parentName="ul">{`Sous Internet Explorer : choisir le menu « outils », puis « options internet », cliquer sur l’onglet
« confidentialité », puis sélectionner le niveau souhaité (`}<a parentName="li" {...{
          "href": "http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies"
        }}>{`http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies`}</a>{`).`}</li>
      <li parentName="ul">{`Sous Mozilla Firefox : se rendre dans le menu « outil » puis dans l’onglet « options ». Il doit ensuite
cliquer sur l’icône « vie privée » « afficher les cookies » et sélectionner les options qui leur
conviennent (`}<a parentName="li" {...{
          "href": "http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies"
        }}>{`http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies`}</a>{`).`}</li>
      <li parentName="ul">{`Sous Opéra : `}<a parentName="li" {...{
          "href": "http://help.opera.com/Windows/10.20/fr/cookies.html"
        }}>{`http://help.opera.com/Windows/10.20/fr/cookies.html`}</a>{`.`}</li>
      <li parentName="ul">{`Sous Safari : se rendre dans le menu de Safari et choisir le menu « préférences » puis cliquer sur
« sécurité » et sélectionnez les options qui leur conviennent (`}<a parentName="li" {...{
          "href": "http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html"
        }}>{`http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html`}</a>{`).`}</li>
      <li parentName="ul">{`Sous Chrome : choisir le menu « outils », sélectionner la rubrique « options », cliquer sur l’onglet
« options avancées » et accéder à la section « confidentialité ». Enfin dans la rubrique « afficher les
cookies » ils doivent choisir les options qui lui conviennent (`}<a parentName="li" {...{
          "href": "http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647"
        }}>{`http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647`}</a>{`).`}</li>
    </ul>
    <p>{`Néanmoins, le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains
services ou réduire et limiter l’accessibilité à la Plateforme et l’expérience. Le cas échéant, ENIA
décline toute responsabilité pour les conséquences liées au fonctionnement dégradé de la
Plateforme.`}</p>
    <h3>{`Les balises (tags) internet`}</h3>
    <p>{`La Plateforme peut employer des balises Internet et les déployer par l’intermédiaire d’un partenaire
spécialiste d’analyses Web, susceptibles de se trouver (et donc de stocker les informations
correspondantes, y compris l’adresse IP de l’Utilisateur) dans un pays étranger.`}</p>
    <p>{`Cette technologie permet à ENIA de suivre le volume, le type et la configuration du trafic et d’évaluer
les réponses des utilisateurs face à la Plateforme ainsi que l’utilisation de la Plateforme par
l’Utilisateur, pour en développer la conception. Le prestataire externe pourra éventuellement recueillir
des informations sur les visiteurs et d’autres sites Internet grâce à ces balises, constituer des rapports
sur l’activité de la Plateforme à destination d’ENIA.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      